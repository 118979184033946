import './App.css';
function App() {
  const RedCA = "Coming Soon"
  const BlueCA = "Coming Soon"
  function handleCopyClick(CA) {
    navigator.clipboard.writeText(CA).then(() => {
      console.log('Text copied to clipboard');
    }).catch(err => {
      console.error('Failed to copy: ', err);
    })
  };
  const openTelegram = () => {
    window.open("https://t.me/RedVsBlueSOL", "_blank");
  };
  const openX = () => {
    window.open("https://x.com/redvsbluesol", "_blank");
  };
  const openBuyRed = () => {
    window.open("https://google.com/", "_blank");
  };
  const openBuyBlue = () => {
    window.open("https://google.com/", "_blank");
  };
  return (
    <div className="App">
      <div className='Header'>
       
        <img src={require("./assets/Logo.png")} className='Logo' alt='' />
      </div>
      <div className="ticker-container">
        <div className="ticker">
          <h1 className="ticker-text">
            <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span>
            <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span>
            <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span>
            <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span>
            <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span>
            <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span>
            <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span>
            <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span>
            <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span>
            <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span>
            <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span>
            <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span>
            <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span>
            <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span>
            <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span>
            <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span>
            <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span>
            <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span>
            <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span>
            <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span>
            <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span>
            <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span>
            <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span>
            <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span>
            <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span>
            <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span>
            <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span>
            <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span>
            <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span>
            <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span>
            <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span> <span className='redtextColor'>$RED</span> <span className='bluetextColor'>$BLUE</span>

          </h1>
        </div>
      </div>
      <div className='RedBioSection'>
        <div className='ColorTextPart'>
          <h1 className='ColorTitle redtextColor'>TEAM RED</h1>
          <p className='ColorParagraph'>A battle-hardened warrior, Red is a master of firearms, specializing in heavy guns and rifles. His scarred eye serves as a reminder of his survival in countless conflicts. Known for his ruthless efficiency and strategic mind, Red fights for dominance with a cold, calculated approach, leaving nothing to chance.</p>
          <div className='Actions'>
            <div className='BuyButton' onClick={openBuyRed}>BUY RED</div>
            <div className='CAContainer'>
              {RedCA}
              <div className='CAButton' onClick={() => handleCopyClick(RedCA)}>copy</div>
            </div>
          </div>
        </div>
      </div>
      <div className='BlueBioSection'>
        <div className='ColorTextPart marginl40'>
          <h1 className='ColorTitle bluetextColor'>TEAM BLUE</h1>
          <p className='ColorParagraph'>Blue is a swift and deadly combatant, wielding a diverse array of sharp melee weapons with unmatched skill. A lover of close-quarters combat, Blue thrives on the thrill of the fight, dancing through battle with precision and agility. Each blade in Blue's arsenal tells a story of victory, and with every strike, he carves his path to supremacy.</p>
          <div className='Actions'>
            <div className='BuyButton' onClick={openBuyBlue}>BUY BLUE</div>
            <div className='CAContainer'>
              {BlueCA}
              <div className='CAButton' onClick={() => handleCopyClick(BlueCA)}> copy</div>
            </div>
          </div>
        </div>
      </div>
      <div className='GuideContainer'>
        <h1>How to buy!</h1>
        <div className='GuideBox'>
        <div className='seperator'/>

          <div className='GuideItem'>
            <h2 className='GuideTitle'><span className='GoldHighlight'>1.</span> Create a wallet with phantom</h2>
            <p className='GuideParagraph'>Visit <a href='https://phantom.app/'target="_blank" rel="noreferrer">Phantom<span className='kufam'>.</span>App</a> and follow the simple steps to create a new account with the phantom app or browser extension</p>
          </div>
          <div className='seperator'/>
          <div className='GuideItem'>
            <h2 className='GuideTitle'><span className='GoldHighlight'>3.</span> Get Some $SOL</h2>
            <p className='GuideParagraph'>Tap the BUY button in the app to purchase Solana, or deposit $SOL to your Phantom wallet from the crypto exchange of your choice</p>
          </div>          
          <div className='seperator'/>
          <div className='GuideItem'>
            <h2 className='GuideTitle'><span className='GoldHighlight'>3.</span> Swap $SOL for $Blue or $Red</h2>
            <p className='GuideParagraph'>Tap the SWAP icon in your Phantom wallet and paste the $Blue or $Red token address, Swap your $SOL for $Blue or $Red</p>
          </div>
          <div className='seperator'/>
          <div className='GuideItem'>
            <h2 className='GuideTitle'><span className='GoldHighlight'>4.</span> You are now a $Blue/$Red holder!</h2>
            <p className='GuideParagraph'>Welcome to the battlefield!</p>
          </div>
          <div className='seperator'/>

        </div>
      </div>
      <div className='ExchangeContainer'>
        <h1>Coming Soon</h1>
        {/* <h3>Soon available for trading on major exchanges.</h3> */}
        <div className='ExchangeItemContainer'>
          <div className='ExchangeItem'><img src={require('./assets/Mystery.png')} alt='?' /></div>
          <div className='ExchangeItem'><img src={require('./assets/Mystery.png')} alt='?' /></div>
          <div className='ExchangeItem'><img src={require('./assets/Mystery.png')} alt='?' /></div>
          <div className='ExchangeItem'><img src={require('./assets/Mystery.png')} alt='?' /></div>
          <div className='ExchangeItem'><img src={require('./assets/Mystery.png')} alt='?' /></div>
          <div className='ExchangeItem'><img src={require('./assets/Mystery.png')} alt='?' /></div>
          <div className='ExchangeItem'><img src={require('./assets/Mystery.png')} alt='?' /></div>
        </div>
        {/* <p>More exchange listings are on the way.</p> */}
      </div>
      <div className='SocialsContainer'>
        <h1>Socials</h1>
        {/* <h3>Keep up with who's winning!</h3> */}
        <div className='SocialsIcons'>
          <div className='XLogo SocialIcon' onClick={openX} />
          <div className='TelegramLogo SocialIcon' onClick={openTelegram} />
        </div>
      </div>
      <div className='Footer'><h3>2024 © All Rights Reserved | redvsbluesol.com</h3></div>
    </div>
  );
}

export default App;
